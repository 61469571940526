/**
 * External dependencies.
 */
import React from 'react';
import PropTypes from 'prop-types';

/**
 * Internal dependencies.
 */
import Position from '../../../components/careers/position';
import Layout from '../../../components/layout';

// import SEO from "../../../components/spot-im-jobs/seo";
// import { getOgImage } from "./../../../utils/functions";

/**
 * Jobs Template Page.
 *
 * @param {Object} props Props
 *
 * @return {*}
 */



export const PositionPage = (props) => {

	const { pageContext: { page, departments, reusableBlocks } } = props;
	// const openGraphImage = getOgImage(seo);

	return (<Layout className="jobs-page"><Position position={page} departments={departments && departments.nodes || []} reusableBlocks={reusableBlocks} /></Layout >);
}

PositionPage.propTypes = {
	pageContext: PropTypes.object,
	uri: PropTypes.string,
};

export default PositionPage;