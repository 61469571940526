/**
 * JobForm Component.
 *
 * @param {Object} props Component props.
 *
 * @return {jsx}
 */
/* External dependencies */
import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useForm, FormProvider } from "react-hook-form";
import Link from '../../../link';
import axios from 'axios';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import ReactHtmlParser from 'react-html-parser';


/* Internal dependencies */
import './style.scss';
import classNames from 'classnames';

/* Constants */
export const LOCATIONS = {
	telaviv: 'Tel Aviv',
	newYork: 'New York',
	ukraine: 'Ukraine'
}

const FIELDS = {
	FIRST_NAME: 'first_name',
	LAST_NAME: 'last_name',
	RESUME: 'resume',
	PHONE: 'phone',
	EMAIL: 'email',
	LINKEDIN: 'linkedin_link',
	GENDER: 'gender',
	HISPANIC_LATINO: 'hispanic_latino',
	// HISPANIC_LATINO: 'hispanic_latino',
	// VETERAN_STATUS: 'veteran_status',
	// VISA_SPONSORSHIP: 'visa_sponsorship',
	// DISABILITY_STATUS: 'disability_status',
	PRIVACY_POLICY: 'privacy_policy',
	ID: 'ID',
	RESUME_LINK: 'resume_text'
}

const allowedTypes = [
	"application/pdf",
	"text/plain",
	"application/vnd.openxmlformats-officedocument.wordprocessingml.document"
];

const RESUME_TYPES = {
	FILE: "file",
	LINK: 'link',
	DROPBOX: 'dropbox'
}

const QUESTOINS_LABELS = {
	LINKEDIN: 'LinkedIn Profile',
	// VISA_REQUIRED: "Will you now or in the future require sponsorship for employment (e.g., H-1B visa status)?",
	// DISABILITY_STATUS: 'How do you know if you have a disability?',
	// VETERAN_STATUS: 'Veteran Status',
	// GENDER: 'Gender',
	// HISPANIC_LATINO: 'Are you Hispanic/Latino?',
	// NEW FIELS
	HOW_DID_YOU_HEAR: "How did you hear about this job?",
	WE_CARE_ABOUT_PRIVACY: "We care about your privacy!\n",
	WE_CARE_ABOUT_PRIVACY_2: "We care about your privacy!",
	RESUME_CV: "Resume/CV",
	COVER_LETTER: "Cover Letter",
	WEBSITE: 'Website',
}


function logFormData(formData) {
	console.log('===============================')
	for (const [key, value] of formData.entries()) {
		console.log(key.toUpperCase());
		console.log(`${value}`);
		console.log('_______________________________')
	}
	console.log('===============================')
}


export const ApplicationForm = ({ job, onFormSwitch }) => {
	const { location, title, id, questions, compliance } = job;
	const [resumeType, setResumeType] = useState(RESUME_TYPES.FILE);
	const [currentFile, setCurrentFile] = useState(null);
	const [applicationSubmitted, setApplicationSubmitted] = useState(false);
	const [applicationError, setApplicationError] = useState(false);

	const test = true;
	const endpoint = test ? "staging" : "application";
	const awsAPI = `https://c8k1b88xw2.execute-api.eu-north-1.amazonaws.com`;

	const complianceFields = [];
	const linkedin = questions?.filter(item => item.label === QUESTOINS_LABELS.LINKEDIN)[0];
	// const visaRequired = questions.filter(item => item.label === QUESTOINS_LABELS.VISA_REQUIRED)[0];
	const howDidYouHear = questions?.filter(item => item.label === QUESTOINS_LABELS.HOW_DID_YOU_HEAR)[0];
	// console.log('questions', questions);

	const jobId = id.replace("Greenhouse__Job__", "");

	/* Required fields: first_name, last_name, email, resume_text || resume, privacy_policy */
	/* Not required fields: linkedin_link */

	const defaultValues = (jobId, reset) => {
		return {
			[FIELDS.FIRST_NAME]: reset ? '' : `Test Name`,
			[FIELDS.LAST_NAME]: reset ? '' : `Test Last Name`,
			[FIELDS.EMAIL]: reset ? '' : `test@openweb.com`,
			[FIELDS.PHONE]: reset ? '' : "+3800000000",
			[FIELDS.LINKEDIN]: reset ? '' : 'https://linkedin.com',
			[FIELDS.PRIVACY_POLICY]: true,
			[FIELDS.ID]: jobId,
		}
	}


	const REGEX = {
		//eslint-disable-next-line
		EMAIL: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
		//eslint-disable-next-line
		STRING: /[A-Za-z]{3}/
	}

	const methods = useForm({
		// resolver: yupResolver(schema),
		//eslint-disable-next-line
		mode: 'onChange',
		reValidateMode: 'onChange',
		defaultValues: defaultValues(jobId, true)
	});
	const {
		watch,
		register,
		handleSubmit,
		formState: { isValid, errors, isSubmitting, isSubmitted, isSubmitSuccessful },
		reset,
		clearErrors,
	} = methods;


	let ComplianceSection = null;

	if (compliance) {


		let CompilanceQuestions = []
		compliance.map((comlianceItem, comlianceItemIndex) => {
			if (comlianceItem?.questions.length === 0) return null;

			const has_disability_status = comlianceItem?.questions?.filter(item => item.fields.filter(field => field.name === 'disability_status').length > 0).length > 0;
			const fields = (<div key={`item-${comlianceItemIndex}`} className="data_compliance_section">
				<Separator />
				{has_disability_status && (
					<>
						<div className="row" >
							<div className="column medium-7">
								<span className="special-subtitle">Form CC-305</span><br />
								<span className="special-subtitle">OMB Control Number 1250-0005</span><br />
								<span className="special-subtitle">Expires 05/31/2023</span><br />
								<h3 className="margin-top-30">Voluntary Self-Identification of Disability</h3>
							</div>
						</div>
					</>
				)}
				<div className='row '><div className='data-compliance-descriotion column medium-7'>{ReactHtmlParser(ReactHtmlParser(comlianceItem.description))}</div></div>

				{comlianceItem.questions?.map((complianceQuestion, questionIndex) => {
					// const fieldName = complianceQuestion.fields[0].name;
					if (complianceQuestion.fields.length === 0) { return null; }
					return (<div key={`${questionIndex}`} className="data_compliance_question">

						{complianceQuestion?.fields?.map((field, index) => {
							const fieldName = field.name;
							complianceFields.push(fieldName);
							let component = null;
							// console.log('complianceQuestion', complianceQuestion);
							switch (fieldName) {
								default:
									component =
										<div key={`${fieldName}-${index}`} >
											<div className="row">
												<div className="column medium-7">
													<h6 className={classNames([{ 'required': complianceQuestion.required }])}>{complianceQuestion.label}</h6>
												</div>
											</div>
											<div className="data_compliance_field">
												{field.type === "multi_value_single_select" && <Select
													name={fieldName} placeholder={field.values[0].label}
													values={field.values}
													register={register}
													errors={errors}
													// label={complianceQuestion.label}
													options={{ required: complianceQuestion.required }}

												/>}
											</div>
										</div>
									break;
							}
							return component;
						})}
					</div >)
				})}

				{/* <Separator /> */}
			</div>);

			CompilanceQuestions = CompilanceQuestions.concat(fields);
			return fields;
		})
		ComplianceSection = CompilanceQuestions;

	}

	// console.log('ComplianceSection', compliance)

	// console.log('questions', questions);
	const customQestions = questions?.map(questoin => {
		return questoin.fields?.map((field, index) => {
			// console.log('field=', field);
			const fieldName = field.name;
			complianceFields.push(fieldName);
			let component = null;
			// console.log('complianceQuestion', questoin);
			switch (fieldName) {
				case FIELDS.FIRST_NAME:
				case FIELDS.LAST_NAME:
				case FIELDS.LINKEDIN:
				case FIELDS.RESUME:
				case FIELDS.PHONE:
				case FIELDS.EMAIL:
					return null;
				default:

					if (Object.values(QUESTOINS_LABELS).includes(questoin.label)) {
						return null;
					}

					component = <div key={`${fieldName}-${index}`} >
						<div className="row">
							<div className="column medium-7">
								<h6 className={classNames([{ 'required': questoin.required }])}>{questoin.label}</h6>
							</div>
						</div>
						<div className="">
							{field.type === "multi_value_single_select" && field.values?.[0] && <Select
								name={fieldName} placeholder={field.values?.[0]?.label}
								values={field.values}
								register={register}
								errors={errors}
								options={{ required: questoin.required }}
							/>}
							{field.type === "input_text" && <Input
								placeholder={questoin.label}
								register={register}
								name={field.name}
								errors={errors}
								options={{
									required: questoin.required,
								}}
							/>}
						</div>
						<Separator />
					</div>

					break;
			}
			return component;
		})
	});


	const onSubmit = async (data) => {
		if (!isValid) return;
		const formData = new FormData();

		// const data_compliance = {
		// 	gdpr_consent_given: data[FIELDS.PRIVACY_POLICY],
		// };


		if (resumeType === RESUME_TYPES.FILE) {
			console.log('FILE');
			formData.append(FIELDS.RESUME, data[FIELDS.RESUME][0]);
			formData.delete(FIELDS.RESUME_LINK);
		} else if (resumeType === RESUME_TYPES.LINK) {
			console.log('LINK');
			formData.append(FIELDS.RESUME_LINK, data[FIELDS.RESUME_LINK]);
			formData.delete(FIELDS.RESUME);
		}


		complianceFields.map((fieldName) => {
			console.log(fieldName);
			if (
				!formData.has(fieldName)
				&& data[fieldName]
				&& fieldName !== FIELDS.RESUME
				&& fieldName !== FIELDS.RESUME_LINK
			) {
				formData.append(fieldName, data[fieldName]);
			}
		});


		questions.map((question) => {
			return question?.fields?.map((field) => {
				if (
					!formData.has(field.name)
					&& field.name in data
					&& (data[field.name] !== undefined || data[field.name] === 0)
					&& field.name !== FIELDS.RESUME
					&& field.name !== FIELDS.RESUME_LINK
				) {
					formData.append(field.name, data[field.name]);
				}
				return null;
			});
		});



		try {
			const res = await axios.post(`https://job-application-submission.openweb.com/${jobId}`, formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			});
			console.log("Success: ", res);
			setApplicationSubmitted(true);
			setApplicationError(false);
			reset(defaultValues(jobId, true));
			clearErrors();
		} catch (error) {
			setApplicationSubmitted(false);
			setApplicationError(true);
			console.error(error)
		}
	}


	useEffect(() => {
		const subscription = watch((value, { name, type }) => {
			if (value && value[FIELDS.RESUME] && value[FIELDS.RESUME].length === 1) { setCurrentFile(value[FIELDS.RESUME][0]); }
		});

		return () => subscription.unsubscribe();
	}, [watch, resumeType]);

	/* ATTACHMENT LOGIC */
	const attachment = useRef(null);

	const { ref, onChange } = register(FIELDS.RESUME, {
		required: resumeType === RESUME_TYPES.FILE,
		validate: {
			// required: value => resumeType === RESUME_TYPES.FILE,
			fileType: v => v && v[0] && allowedTypes.indexOf(v[0].type) !== -1
		},
		onChange: (e) => {
			// methods.resetField(FIELDS.RESUME);
			// clearErrors(FIELDS.RESUME)
			// console.log(isValid)
			// console.log('onchange - ', e)
		},
		// onBlur: (e) => {
		// 	console.log('onBlur', e)
		// }
	});


	const handleAttachmentClick = () => {
		setResumeType(RESUME_TYPES.FILE);
		const currentSelection = methods.getValues(FIELDS.RESUME);

		if (!currentFile && (!currentSelection || currentSelection.length !== 1)) {
			attachment.current.click();
		}
	};

	const handleLinkClick = () => {
		setResumeType(RESUME_TYPES.LINK);
	}

	const removeFile = () => {
		setCurrentFile(null);
		methods.resetField(FIELDS.RESUME);
	}

	return (
		<>
			{/* {response ? <iframe dangerouslySetInnerHTML={{ __html: HtmlParser(response) }}></iframe> : ''} */}
			<FormProvider {...methods} >
				<form action="#" onSubmit={handleSubmit(onSubmit)} encType='multipart/form-data'>
					<div className="IN-Awli-widget"></div>
					<div className="ow-application-form">
						{ /* eslint-disable-next-line */}
						<a href="" onClick={onFormSwitch} className="back-button" role="button" tabIndex={0}><svg xmlns="http://www.w3.org/2000/svg" width="10" height="5" viewBox="0 0 10 5"><path fill="#000" fillRule="evenodd" d="M0 0L5 5 10 0z"></path></svg>Back</a>
						<span className="application-for">Application for</span>
						<div className="row application-header">
							<div className="column small-12 medium-8">
								<h2 className="job-title">{title}<br />in {location.name}</h2>
							</div>
							<div className="column small-12 medium-4">
								{/* <button className="apply-with-linkedin">Apply with linkedin</button> */}
							</div>
						</div>
						<h3 className="margin-top-100">General info</h3>
						<div className="row">
							<div className="column small-12 medium-4">
								<Input
									placeholder="Your first name"
									register={register}
									name={FIELDS.FIRST_NAME}
									label="First name"
									errors={errors}
									options={{
										required: true,
										maxLength: 80,
										minLength: 2,
										//eslint-disable-next-line
										pattern: REGEX.STRING

									}}
								/>
								<Input
									placeholder="Your last name"
									register={register}
									name={FIELDS.LAST_NAME}
									label="Last name"
									errors={errors}
									options={{
										required: true,
										maxLength: 100,
										minLength: 2,
										//eslint-disable-next-line
										pattern: REGEX.STRING
									}}
								/>
								<Input
									placeholder="Your e-mail address"
									register={register}
									name={FIELDS.EMAIL}
									label="Email"
									errors={errors} options={{
										required: true,
										maxLength: 200,
										//eslint-disable-next-line
										pattern: REGEX.EMAIL
									}} />
								<InputPhone
									label="Phone number"
									errors={errors}
									name={FIELDS.PHONE}
									register={register}
								/>
							</div>
							<div className="column small-12 medium-1" />
							<div className="column small-12 medium-4">
								{linkedin && <>
									<span className="form-subtitle required">Please add below one or both of the following</span>
									<Input
										placeholder="Your Linkedin profile link"
										register={register}
										errors={errors}
										name={linkedin.fields[0].name}
										label="Linkedin profile"
										options={{ required: linkedin.required }}
									/>
								</>}

								<input
									type="file"
									name={FIELDS.RESUME}
									ref={(e) => {
										ref(e)
										attachment.current = e
									}}
									onChange={onChange}
									placeholder="Resume/CV or your website"
									accept=".txt,.pdf,.docx"
									className="input-field-file"
								/>


								<label htmlFor={FIELDS.RESUME} className="required">Resume/CV or your website</label>
								{errors[FIELDS.RESUME] && <ErrorField />}
								<div className="actions">
									<a onClick={handleAttachmentClick} role="button" tabIndex={0}>Attach</a>
									<a onClick={handleLinkClick} role="button" tabIndex={0}>Link</a>
								</div>
								{resumeType === RESUME_TYPES.LINK && <Input
									register={register}
									options={{
										required: resumeType === RESUME_TYPES.LINK,
										validate: {
											// required: value => resumeType === RESUME_TYPES.LINK,
											minLength: value => value.length > 6,
											maxLength: value => value.length < 200,
										}
									}}
									errors={errors}
									name={FIELDS.RESUME_LINK}
									placeholder="Enter your resume link"
								/>}

								{resumeType === RESUME_TYPES.FILE && currentFile && currentFile.name && currentFile.name.length > 0 && <div className="file-details">
									{ /*eslint-disable-next-line */}
									<span className="file-name">{currentFile.name}</span><span role="button" tabIndex={0} className="remove-file-button" onClick={() => removeFile()}><svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11" className="menu-close"><g fillRule="evenodd"><path d="M0.75 0L9 8.25 8.25 9 0 0.75z" transform="translate(1 1)"></path><path d="M0 8.25L8.25 0 9 0.75 0.75 9z" transform="translate(1 1)"></path></g></svg></span>
								</div>}

							</div>
							{/* errors will return when field validation fails  */}
						</div>
						{ComplianceSection}
						<Separator />
						{customQestions}


						{howDidYouHear ? <>
							<div className="row">
								<div className="column medium-7">
									<h6>How did you hear about this job?</h6>
								</div>
							</div>
							<Input
								register={register}
								options={{
									required: howDidYouHear.required,
									validate: {
										maxLength: value => value.length < 200,
									}
								}}
								errors={errors}
								name={howDidYouHear.fields[0].name}
								placeholder="Enter how did you hear about this job?"
							/></> : null}
					</div>
					<div className="row ow-application-form-submit-wrapper">
						<div className="column small-12">
							{applicationSubmitted && !applicationError && <div className="submition-success-message">Application was submitted! Thank you!</div>}
							{!applicationSubmitted && applicationError && <div className="submition-success-error">Application was not submitted! <br></br> <span className='reach-out-message'>Please reach out to us directly to <span className='reachout-email'>talent-acquisition@openweb.com</span>.</span></div>}
							{isSubmitting && 'Submitting application...'}
							<div className="submit-application">
								<input type="hidden" value={jobId} {...register(FIELDS.ID, { required: true })} />
								<input type="submit" value="Submit application" disabled={!isValid || isSubmitting} />
							</div>
						</div>
						<div className="column small-12">
							<div className="privacy-policy">
								{errors.privacy_policy && <ErrorField />}
								<div className="privacy-policy-inner">
									<input type="checkbox"  {...register(FIELDS.PRIVACY_POLICY, { required: true })} />
									<div className="privacy-policy-label">
										<label htmlFor={FIELDS.PRIVACY_POLICY}>I agree with the</label>
										<Link url="/legal-and-privacy/" target="_blank" rel="noreferrer" title="Privacy Policy">OpenWeb Privacy Practices</Link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</form>
			</FormProvider>
		</>
	);
};

ApplicationForm.propTypes = {
	job: PropTypes.shape({}),
	handleFormSwitch: PropTypes.func
};

export default ApplicationForm;



const Separator = () => (<div className="row">
	<div className="column small-12 medium-10">
		<hr />
	</div>
</div>);



const ErrorField = () => (<span className="required-field-error">This field is required</span>);

const Input = ({ register, name, placeholder, label, errors, options, ...rest }) => {
	const labelClasses = options && options.required ? "required" : "";
	return (<>
		{label ? <label htmlFor={name} className={labelClasses}>{label}</label> : ''}
		{errors[name] && <ErrorField />}
		{/* {errors[name] && errors[name].type === "required" && <span>This is required</span>} */}
		{/* {errors[name] && errors[name].type === "maxLength" && <span>Max length exceeded</span>} */}
		<input type="text" placeholder={placeholder}  {...register(name, { ...options })} {...rest} />
	</>);
}


const Select = ({ name, placeholder, values = [], label, labelClass = "", errors, register, options = {}, ...rest }) => {
	const labelClasses = options && options.required ? "required" : "";
	return (<div className='select-wrapper'>
		{label ? <label htmlFor={name} className={`${labelClasses} ${labelClass}`}>{label}</label> : ''}
		{errors[name] && <ErrorField />}
		{values.length > 0 && <select defaultValue={values[0].value} {...register(name, { ...options })} {...rest} >
			<option value={values[0].label} disabled >{placeholder}</option>
			{values?.map((item, index) => <option key={`select-option-${index}`} value={item?.value || item.value === 0 ? item.value : item}>{item?.label ? item.label : item}</option>)}
		</select>}
	</div>);
}


const InputPhone = ({ register, name, labelClasses, label, errors }) => {
	const field = register(name, { required: true, minLength: 8, maxLegth: 15 });
	return (<>
		{label ? <label htmlFor={name} className="required">{label}</label> : ''}
		{errors[name] && <ErrorField />}
		<PhoneInput
			country={'us'}
			containerClass={'phone-input-container'}
			/*eslint-disable-next-line */
			onChange={(value) => {
				field.onChange({
					type: "text",
					target: {
						name,
						value,
						// _f: field.ref
					}
				});
			}}
		// onBlur={field.onBlur}
		/>
	</>);
}

/* 
		Validations on POST Requests for V1 API

		The job must be live and published
		The job board must be live and published
		The job post ID must exist on the job board with the supplied board_token

		Field validations:

		first_name, last_name, and email fields are required
		first_name, last_name, and phone fields must not contain a URL
		email field must contain a valid email address
		educations object must contain valid school_name_id, degree_id, discipline_id, start_date, and end_date
		employments object must contain company_name, title, start_date and current (must be true or false). If current=false, must contain end_date
		start_date must be before end_date
		Field values must match the field type specified on the job post question

		Attachments:

		Must be a supported file type (pdf, doc, docx, txt, rtf)
		File size must be > 0 bytes

		endpoint POST https://boards-api.greenhouse.io/v1/boards/{board_token}/jobs/{id}
		board_token = openweb
		job_id = id
		first_name
		last_name
		email
		phone
		resume
		cover_letter
		educations
		employments:
			company_name
			title
			start_date
			current (must be true or false) . If current is false, must have end_date. start_date and end_date will use a hash of month and year.
		
		Submitting Attachments
		
		We support 4 methods of uploading attachments when submitting a candidate application:

		Submit the attachment via direct upload using multipart/form-data.
		Submit the attachment via direct upload using application/json.
		Submit a path to the attachment on an external server.
		Submit the plaintext file contents.
		
		*/