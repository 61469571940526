/**
 * Jobs Template Page.
 *
 * @param {Object} props Props
 *
 * @return {*}
 */

/**
 * External dependencies.
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import "./job-single-page.scss";
import { collectInnerBlocks } from '@blocks/MapBlocks/MapBlocks';
import { parse } from '@wordpress/block-serialization-default-parser';

import ApplicationForm from './ApplicationForm';
import CareersHowWeHire from '@components/careers/careers-how-we-hire';


// import SEO from "../../../components/spot-im-jobs/seo";
// import { getOgImage } from "./../../../utils/functions";




export const Jobs = (props) => {
	const { position, departments, reusableBlocks } = props;
	const positionDepartment = position.location.name;
	// console.log(position);
	// const openGraphImage = getOgImage(seo);
	// const parser = new DOMParser();
	// var content = parser.parseFromString(page.content, 'text/html');

	const [isFormVisible, setIsFormVisible] = useState(false);

	const handleFormSwitch = () => {
		setIsFormVisible(!isFormVisible);
		document.body.scrollTop = 0;
		document.documentElement.scrollTop = 0;
	}

	const AppForm = () => (<div className="jobs-page">
		<ApplicationForm job={position} onFormSwitch={handleFormSwitch} />
	</div>);

	const htmlParsed = ReactHtmlParser(position.content)


	const DepartmentInfo = () => {
		const filterDepartment = departments.filter(item => item.title === positionDepartment);
		const obj = filterDepartment[0] || false;
		const { content, contentRaw } = obj;
		const parsedContent = parse(contentRaw ?? '');

		let layout = collectInnerBlocks(parsedContent, reusableBlocks, parsedContent, content);

		return (obj ? <>{layout && layout[0]}</> : <></>);
	}


	const JobDescription = () => (<div className="jobs-page">
		<div className="ow-job-header">
			<span className="ow-job-location" >{position.location.name}</span>
			<h1 className="ow-job-title">{position.title}</h1>
			<button className="apply-now-button" onClick={() => handleFormSwitch()}>Apply now</button>
		</div>
		<div className="row ow-job-content">
			<div className="ow-job-content-left medium-4 small-12 column">
				<h2>About<br /> the job</h2>
			</div>
			<div className="ow-job-content-right medium-8 small-12 column" dangerouslySetInnerHTML={{ __html: htmlParsed }}></div>
		</div>
		<DepartmentInfo />
		<button className="apply-for-the-role-button" onClick={() => handleFormSwitch()}>Apply for the role</button>
		<CareersHowWeHire />
	</div>);


	return (<>
		{isFormVisible ? <AppForm /> : <JobDescription />}
	</>);
}

Jobs.propTypes = {
	pageContext: PropTypes.object,
	uri: PropTypes.string,
};

export default Jobs;